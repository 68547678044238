exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-properties-index-js": () => import("./../../../src/pages/commercial-properties/index.js" /* webpackChunkName: "component---src-pages-commercial-properties-index-js" */),
  "component---src-pages-off-plan-properties-index-js": () => import("./../../../src/pages/off-plan-properties/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/career-details.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-career-landing-template-js": () => import("./../../../src/templates/career-landing-template.js" /* webpackChunkName: "component---src-templates-career-landing-template-js" */),
  "component---src-templates-community-details-js": () => import("./../../../src/templates/community-details.js" /* webpackChunkName: "component---src-templates-community-details-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-form-template-js": () => import("./../../../src/templates/form-template.js" /* webpackChunkName: "component---src-templates-form-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-insight-details-js": () => import("./../../../src/templates/insight-details.js" /* webpackChunkName: "component---src-templates-insight-details-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/listing-template.js" /* webpackChunkName: "component---src-templates-listing-template-js" */),
  "component---src-templates-offplan-details-js": () => import("./../../../src/templates/offplan-details.js" /* webpackChunkName: "component---src-templates-offplan-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-static-template-js": () => import("./../../../src/templates/static-template.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */)
}

